import React, {useEffect, useState} from "react";
import ReactPlayer from "react-player/lazy";

export default function VideoPlayer() {
  const videoW = window.innerWidth;
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth + 210,
    height: window.innerHeight + 115,
  });
  // useLayoutEffect(() => {})

  useEffect(() => {
    const handleResize = () => {
      if (videoW <= 480) {
        setWindowSize({
          width: window.innerWidth + 1300,
          height: window.innerHeight + 1300,
        });
      } else if (videoW <= 920) {
        setWindowSize({
          width: window.innerWidth + 1300,
          height: window.innerHeight + 1300,
        });
      } else if (videoW <= 1300) {
        setWindowSize({
          width: window.innerWidth + 700,
          height: window.innerHeight + 700,
        });
      } else {
        setWindowSize({
          width: window.innerWidth + 210,
          height: window.innerHeight + 115,
        });
      }
    };
    handleResize();
  }, []); // 컴포넌트가 처음 마운트될 때만 이펙트를 실행합니다.

  useEffect(() => {
    const handleTop = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    };
    window.addEventListener("resize", handleTop);
    return () => {
      window.removeEventListener("resize", handleTop);
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <ReactPlayer
        className="absolute object-cover object-center w-full h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        url={"https://www.youtube.com/watch?v=eLnQnnfOtdA"} // 플레이어 url
        width={windowSize.width}
        height={windowSize.height}
        playing={true}
        muted={true}
        controls={false}
        light={false}
        pip={false}
        loop={true}
        poster={
          "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
        }
        type="video/mp4"
      />
    </div>
  );
}
