import {useEffect, useRef, useState} from "react";
import Calendly from "../components/Calendly";
import EmailInputModal from "../components/EmailInputModal";
import VideoPlayer from "../components/VideoPlayer";
import {isAndroid, isWindows} from "react-device-detect";
import JPEmailModal from "../components/JPEmailModal";

// import Calendly from "./components/Calendly";
// import EmailInputModal from "./components/EmailInputModal";

function MainPage() {
  //   const [windowObj, setWindowObj] = useState();
  const [open, setOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const firstAudio = "/musics/clair de lune.mp3";
  const secondAudio = "/musics/sharpFreeMeek.mp3";
  const thirdAudio = "/musics/violent crimes demo.mp3";
  const [currentMusic, setCurrentMusic] = useState("");
  const prevMusic = useRef(currentMusic);
  const audioRef = useRef(new Audio(currentMusic));
  const [muted, setMuted] = useState(true);
  const [title, setTitle] = useState("");

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.matchMedia("(orientation: portrait)").matches) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
        // document.body.style.wid
      }
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  useEffect(() => {
    // if (typeof window !== "undefined") {
    // audioRef.current.play();
    if (currentMusic !== prevMusic.current) {
      if (audioRef.current) {
        // 다른데 실행중이다.
        // 현재 음악이 재생 중이라면 일시 정지합니다.
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // 재생 시간을 초기화합니다.
      }
      // 새로운 음악을 로드하고 재생합니다.
      audioRef.current.src = currentMusic;
      audioRef.current.volume = 0.5;
      audioRef.current.play();
    }
    // }
    // prevMusic 업데이트
    prevMusic.current = currentMusic;
  }, [currentMusic]);

  useEffect(() => {
    if (audioRef.current) {
      if (muted) {
        audioRef.current.pause();
      } else {
        audioRef.current.volume = 0.5;
        audioRef.current.play();
      }
    }
  }, [muted]);

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();

    // 모바일 터치 스크롤 막기
    window.addEventListener("touchmove", preventDefault, {passive: false});

    // PC에서 마우스 휠 스크롤 막기
    window.addEventListener("wheel", preventDefault, {passive: false});

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("touchmove", preventDefault);
      window.removeEventListener("wheel", preventDefault);
    };
  }, []);

  // 버튼을 클릭하면 음악을 변경합니다.
  const changeMusic = (music) => {
    setCurrentMusic(music);
    music.includes("clair")
      ? setTitle("message 01")
      : music.includes("FreeMeek")
      ? setTitle("message 02")
      : music.includes("violent") && setTitle("message 03");
  };

  const [sCalendar, setSCalendar] = useState(false);
  const [jCalendar, setJCalendar] = useState(false);
  const [sProfile, setSProfile] = useState(false);
  const [jProfile, setJProfile] = useState(false);
  const [sInsta, setSInsta] = useState(false);
  const [jInsta, setJInsta] = useState(false);
  const [KRModal, setKRModal] = useState(false);
  const [JPModal, setJPModal] = useState(false);

  return (
    <div className={`flex items-center justify-center w-full h-screen `}>
      <VideoPlayer />
      <div
        className={`w-full h-screen absolute top-0 left-0 z-[999] bg-[transparent] min-h-screen`}
      >
        <div
          className={`flex w-full h-screen pt-10 pl-4 xl:pt-20 lg:pt-20 md:pt-10 sm:pt-10 xs:pt-10 xxs:pt-10 xl:pl-6 lg:pl-6 md:pl-4 sm:pl-4 xs:pl-4 xxs:pl-4`}
        >
          <div className="w-[600px]">
            <div className="mb-8">
              <h4 className="text-[20px] text-[#ffffff] mb-2">SEOUL</h4>
              <div className="flex items-center gap-2">
                <div
                  // value={"CALENDLY"}
                  onMouseOver={() => setSCalendar(true)}
                  onMouseLeave={() => setSCalendar(false)}
                  className="bg-[white] w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-start items-center"
                >
                  {sCalendar && (
                    <button
                      onClick={() => setOpen(true)}
                      onMouseLeave={() => setSCalendar(false)}
                      className=" bg-[white] text-[black] rounded-full animate-navButton"
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[2px]"
                        }`}
                      >
                        MEET
                      </span>
                    </button>
                  )}
                </div>
                <div
                  // value={"회사소개서"}
                  onMouseOver={() => setSProfile(true)}
                  onMouseLeave={() => setSProfile(false)}
                  className="bg-white w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-center items-center"
                >
                  {sProfile && (
                    // <div className="absolute flex justify-center">
                    <button
                      onClick={() => {
                        setKRModal(true);
                        // setRegion("Seoul");
                      }}
                      onMouseLeave={() => setSProfile(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-secondNavButton "
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[2px]"
                        }`}
                      >
                        PROFILE
                      </span>
                    </button>
                    // </div>
                  )}
                  {/* <MdOutlineDescription size={40} /> */}
                </div>
                <div
                  onMouseOver={() => setSInsta(true)}
                  onMouseLeave={() => setSInsta(false)}
                  className="bg-white w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-end items-center"
                >
                  {sInsta && (
                    <a
                      href={"https://www.instagram.com/horizon.kr/tagged/"}
                      onMouseLeave={() => setSInsta(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-navButton flex justify-center items-center text-center"
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[3px]"
                        }`}
                      >
                        INSTAGRAM
                      </span>
                    </a>
                  )}
                  {/* <FaInstagram size={40} /> */}
                </div>
              </div>
            </div>
            <div>
              <h4 className="text-[20px] text-[white] mb-2">TOKYO</h4>
              <div className="flex items-center gap-2">
                <div
                  // value={"CALENDLY"}
                  onMouseOver={() => setJCalendar(true)}
                  onMouseLeave={() => setJCalendar(false)}
                  className={`bg-white w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-start items-center`}
                >
                  {jCalendar && (
                    <button
                      onClick={() => setOpen(true)}
                      onMouseLeave={() => setJCalendar(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-navButton"
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[2px]"
                        }`}
                      >
                        MEET
                      </span>
                    </button>
                  )}
                </div>
                <div
                  // value={"회사소개서"}
                  onMouseOver={() => setJProfile(true)}
                  onMouseLeave={() => setJProfile(false)}
                  className="bg-white w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-center items-center"
                >
                  {jProfile && (
                    <button
                      onClick={() => {
                        setJPModal(true);
                        // setRegion("Tokyo");
                      }}
                      onMouseLeave={() => setJProfile(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-secondNavButton"
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[2px]"
                        }`}
                      >
                        PROFILE
                      </span>
                    </button>
                  )}
                </div>
                <div
                  onMouseOver={() => setJInsta(true)}
                  onMouseLeave={() => setJInsta(false)}
                  className="bg-white w-[16px] h-[16px] rounded-full hover:bg-[#ffffffb7] flex justify-end items-center"
                >
                  {jInsta && (
                    <a
                      href={"https://www.instagram.com/horizon.jp.hq/tagged/"}
                      onMouseLeave={() => setJInsta(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-navButton flex justify-center items-center text-center"
                    >
                      <span
                        className={`block leading-[16px] ${
                          (isAndroid || isWindows) && "pt-[3px]"
                        }`}
                      >
                        INSTAGRAM
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="absolute bottom-28 xl:bottom-10 lg:bottom-10 md:bottom-28 sm:bottom-28 xs:bottom-28 xxs:bottom-28">
              <div className="w-[100px] overflow-hidden">
                <div className="w-[180px] mb-2 animate-slider text-nowrap text-[white]">
                  {currentMusic
                    .replace("/musics/", `${title} - `)
                    .replace(".mp3", "")
                    .replace("sharp", "#")}
                </div>
              </div>

              <div className="flex gap-2">
                <button
                  onClick={() => changeMusic(firstAudio)}
                  // onMouseOver={() => setHoverFMusic(true)}
                  // onMouseLeave={() => setHoverFMusic(false)}
                  className="rounded-full bg-[white] w-[16px] h-[16px] flex justify-start items-center"
                >
                  {/* {hoverFMusic && (
                    <button
                      onClick={() => changeMusic(firstAudio)}
                      onMouseLeave={() => setHoverFMusic(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-navButton overflow-hidden "
                    >
                      <div className="">
                        {firstAudio.replace("/musics/", "").replace(".mp3", "")}
                      </div>
                    </button>
                  )} */}
                </button>
                <button
                  onClick={() => changeMusic(secondAudio)}
                  // onMouseOver={() => setHoverSMusic(true)}
                  // onMouseLeave={() => setHoverSMusic(false)}
                  className="rounded-full bg-[white] w-[16px] h-[16px] flex justify-center items-center"
                >
                  {/* {hoverSMusic && (
                    <button
                      onClick={() => changeMusic(secondAudio)}
                      onMouseLeave={() => setHoverSMusic(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-secondNavButton overflow-hidden "
                    >
                      <div className="">
                        {secondAudio
                          .replace("sharp", "#")
                          .replace("/musics/", "")
                          .replace(".mp3", "")}
                      </div>
                    </button>
                  )} */}
                </button>
                <button
                  onClick={() => changeMusic(thirdAudio)}
                  // onMouseOver={() => setHoverTMusic(true)}
                  // onMouseLeave={() => setHoverTMusic(false)}
                  className="rounded-full bg-[white] w-[16px] h-[16px] flex justify-end items-center"
                >
                  {/* {hoverTMusic && (
                    <button
                      onClick={() => changeMusic(thirdAudio)}
                      onMouseLeave={() => setHoverTMusic(false)}
                      className="absolute bg-[white] text-[black] rounded-full animate-navButton overflow-hidden "
                    >
                      <div className="">
                        {thirdAudio.replace("/musics/", "").replace(".mp3", "")}
                      </div>
                    </button>
                  )} */}
                </button>
                {/* <button
                  onClick={() => setMuted(!muted)}
                  className="rounded-full w-[16px] h-[16px] flex justify-center items-center text-[white] hover:text-[#cacaca]"
                >
                  {muted ? <GoMute size={16} /> : <GoUnmute size={16} />}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmailInputModal open={KRModal} onModalClose={() => setKRModal(false)} />

      <JPEmailModal open={JPModal} onModalClose={() => setJPModal(false)} />

      <Calendly open={open} onModalClose={() => setOpen(false)} />
    </div>
  );
}

export default MainPage;
